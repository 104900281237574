import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1023.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1023.000000) scale(0.100000,-0.100000)">
					<path d="M4244 8251 c-84 -14 -224 -65 -224 -82 0 -5 -5 -9 -11 -9 -19 0 -126
-89 -173 -143 -57 -67 -102 -156 -127 -254 -18 -74 -19 -124 -19 -1660 l0
-1583 2182 0 2182 0 -55 58 c-88 92 -189 202 -264 287 -38 44 -100 111 -136
150 -37 38 -84 90 -105 114 -47 54 -52 59 -184 201 -58 62 -113 122 -123 134
-10 12 -42 46 -70 76 -29 30 -72 78 -97 105 -25 28 -94 104 -155 169 -60 65
-123 135 -140 155 -16 19 -55 63 -86 96 -108 117 -195 211 -248 270 -29 33
-83 92 -120 130 -36 39 -104 113 -151 165 -131 146 -163 181 -195 216 -53 56
-99 107 -154 169 -30 33 -104 113 -165 179 -61 65 -122 132 -136 149 -14 17
-34 40 -45 52 -26 27 -126 136 -190 205 -28 30 -139 152 -247 271 -108 119
-214 230 -235 246 -54 43 -140 90 -206 112 -66 23 -225 34 -303 22z"/>
					<path d="M8310 8235 c-89 -20 -130 -38 -210 -91 -64 -42 -183 -156 -276 -264
-21 -25 -66 -74 -100 -110 -34 -36 -79 -85 -100 -110 -21 -25 -71 -79 -110
-120 -74 -78 -223 -242 -583 -641 -52 -57 -119 -131 -150 -164 -97 -104 -95
-101 -79 -124 13 -18 78 -90 433 -476 130 -141 280 -305 365 -399 25 -27 70
-77 101 -110 30 -34 134 -147 230 -252 96 -105 215 -235 264 -289 50 -54 124
-136 165 -182 41 -45 136 -150 210 -232 l135 -150 263 0 262 -1 -3 1588 c-3
1752 2 1612 -66 1757 -78 167 -231 298 -416 355 -91 28 -246 35 -335 15z"/>
					<path d="M7973 4313 c-9 -3 -13 -32 -13 -95 l0 -90 -32 7 c-129 31 -208 -42
-208 -193 0 -110 40 -177 115 -194 33 -7 82 10 104 35 16 20 31 13 31 -14 0
-15 7 -19 33 -19 60 0 58 -7 57 280 0 175 -4 268 -12 277 -10 13 -51 16 -75 6z
m-39 -253 c25 -9 26 -12 26 -99 0 -85 -1 -91 -26 -110 -64 -50 -114 -7 -114
96 0 72 11 100 43 113 29 12 41 12 71 0z"/>
					<path d="M3742 4268 c-9 -9 -12 -80 -12 -260 l0 -248 27 -11 c14 -5 39 -7 54
-4 l29 6 2 167 3 167 59 -102 58 -103 43 0 44 0 58 103 58 102 3 -167 2 -167
34 -7 c19 -3 43 -1 55 5 20 11 21 18 21 260 0 281 2 274 -77 269 l-48 -3 -70
-122 c-38 -67 -73 -124 -76 -128 -4 -3 -35 45 -69 107 -35 62 -70 121 -78 131
-18 20 -101 24 -120 5z"/>
					<path d="M8570 4221 c-5 -11 -10 -31 -10 -45 0 -24 -14 -41 -35 -41 -5 0 -11
-17 -13 -37 -3 -30 0 -37 18 -40 19 -3 21 -10 23 -125 2 -106 5 -125 23 -150
24 -32 53 -41 109 -33 38 5 40 7 43 43 3 36 3 37 -32 37 -19 0 -36 4 -38 10
-1 5 -2 57 0 115 l3 105 30 0 c28 0 32 5 32 45 0 32 -4 35 -36 35 -32 0 -32 1
-29 41 5 46 -6 59 -50 59 -17 0 -31 -7 -38 -19z"/>
					<path d="M4455 4138 c-74 -26 -109 -90 -108 -199 0 -131 75 -204 198 -197 69
5 135 32 135 57 0 9 -7 26 -15 38 -15 21 -17 21 -60 6 -56 -19 -109 -11 -130
20 -30 42 -22 47 83 47 86 0 101 3 117 20 14 16 16 29 11 66 -16 122 -115 182
-231 142z m124 -112 c18 -37 3 -47 -64 -44 -39 2 -51 7 -53 21 -11 58 90 78
117 23z"/>
					<path d="M4824 4136 c-87 -39 -108 -142 -43 -212 19 -20 19 -21 -1 -39 -22
-20 -26 -55 -9 -86 9 -17 6 -25 -15 -44 -51 -47 -44 -108 16 -145 26 -16 51
-20 119 -20 138 0 202 40 202 124 0 25 -5 52 -12 60 -17 20 -96 56 -123 56
-41 0 -98 21 -98 35 0 15 17 21 88 28 23 3 54 13 68 22 33 21 57 79 49 115 -5
24 -3 29 17 32 17 2 24 11 26 32 2 16 0 32 -5 36 -4 4 -62 11 -128 14 -92 5
-127 3 -151 -8z m126 -86 c24 -24 24 -29 4 -58 -10 -15 -25 -22 -48 -22 -39 0
-56 15 -56 52 0 47 63 65 100 28z m29 -325 c35 -33 -30 -65 -105 -51 -42 8
-53 30 -28 57 16 17 26 19 69 14 27 -4 56 -13 64 -20z"/>
					<path d="M5196 4136 c-54 -20 -62 -30 -50 -63 8 -22 15 -27 34 -25 136 15 138
15 150 -8 19 -35 6 -47 -59 -53 -104 -9 -151 -53 -151 -142 0 -63 31 -96 96
-102 41 -4 55 -1 86 21 37 25 38 25 48 6 8 -15 21 -20 50 -20 21 0 42 5 45 11
4 6 6 72 6 147 -2 155 -15 197 -66 221 -42 21 -142 24 -189 7z m144 -236 c0
-26 -43 -70 -67 -70 -29 0 -45 16 -41 41 3 27 40 49 81 49 21 0 27 -5 27 -20z"/>
					<path d="M5853 4139 c-77 -15 -96 -35 -73 -78 8 -17 13 -17 69 -2 75 19 111 7
111 -38 0 -27 -2 -29 -57 -36 -74 -9 -96 -18 -127 -52 -21 -22 -26 -38 -26
-78 0 -61 19 -91 64 -103 44 -13 84 -5 123 25 l32 24 7 -26 c5 -20 12 -25 38
-25 58 0 59 5 57 156 -3 161 -14 198 -63 218 -47 19 -106 25 -155 15z m99
-214 c19 -8 11 -59 -12 -80 -11 -10 -31 -15 -52 -13 -26 2 -34 8 -36 26 -4 30
13 50 52 61 17 5 32 10 33 10 1 1 8 -1 15 -4z"/>
					<path d="M6323 4140 c-12 -5 -28 -18 -35 -30 -17 -27 -24 -25 -32 6 -7 25 -10
26 -49 20 -23 -4 -44 -10 -47 -14 -3 -4 -3 -88 0 -187 l5 -180 29 -3 c15 -2
39 -1 52 2 24 6 24 8 24 141 0 148 4 157 65 152 25 -2 30 2 38 36 6 27 6 42
-3 52 -14 17 -16 17 -47 5z"/>
					<path d="M6535 4143 c-98 -18 -145 -80 -145 -192 0 -93 30 -158 85 -184 51
-24 153 -24 202 0 42 20 50 39 29 68 -13 18 -18 18 -48 7 -73 -26 -153 -7
-159 38 -4 39 -1 40 100 40 120 0 137 10 127 74 -17 107 -94 166 -191 149z
m66 -82 c8 -1 29 -49 29 -66 0 -12 -16 -15 -71 -15 l-71 0 6 28 c9 37 43 63
75 58 14 -2 29 -5 32 -5z"/>
					<path d="M7440 4139 c-54 -7 -100 -25 -100 -38 0 -5 4 -21 9 -34 l9 -24 62 16
c54 14 63 14 88 1 16 -10 28 -26 30 -41 3 -25 0 -27 -59 -33 -72 -9 -109 -25
-138 -62 -39 -49 -18 -158 32 -169 65 -14 101 -9 135 20 36 30 42 31 42 5 0
-26 23 -35 63 -24 l31 10 -3 139 c-3 151 -14 195 -53 216 -32 18 -95 25 -148
18z m98 -239 c4 -38 -36 -72 -79 -68 -27 2 -35 8 -37 26 -4 30 13 50 52 61 45
14 61 9 64 -19z"/>
					<path d="M8245 4140 c-22 -4 -53 -12 -69 -19 -24 -10 -27 -15 -21 -39 9 -37
20 -41 68 -25 51 18 103 9 118 -18 17 -34 4 -46 -58 -54 -90 -11 -138 -44
-149 -103 -22 -117 85 -179 184 -105 l32 24 10 -25 c7 -19 17 -26 37 -26 53 0
55 6 54 155 -2 154 -13 195 -58 218 -36 18 -97 25 -148 17z m103 -241 c4 -37
-37 -71 -79 -67 -28 2 -35 8 -37 29 -4 30 15 49 61 59 44 11 52 8 55 -21z"/>
					<path d="M8879 4140 c-80 -14 -105 -39 -80 -79 8 -12 19 -12 70 -2 73 14 111
2 122 -41 4 -15 4 -27 1 -26 -4 1 -31 -1 -60 -5 -79 -11 -131 -35 -147 -68
-20 -37 -19 -104 1 -134 32 -49 113 -53 170 -10 33 25 44 24 44 -7 0 -14 8
-18 33 -18 59 0 60 5 58 152 -1 73 -6 147 -12 165 -19 59 -102 89 -200 73z
m109 -239 c2 -14 -6 -33 -19 -47 -35 -37 -99 -30 -99 11 0 28 20 45 63 55 43
10 51 7 55 -19z"/>
					<path d="M6759 4135 c-6 -5 94 -356 106 -376 4 -5 26 -9 50 -9 50 0 46 -8 80
155 10 44 21 87 26 95 10 19 11 16 39 -115 31 -146 25 -135 75 -135 24 0 47 5
50 10 7 11 90 317 97 355 3 21 -19 30 -56 21 -19 -5 -26 -22 -51 -126 -29
-119 -40 -141 -50 -97 -2 12 -15 67 -28 122 l-23 100 -40 3 c-25 2 -45 -2 -51
-10 -5 -7 -20 -60 -32 -118 -31 -144 -32 -146 -41 -129 -4 8 -15 61 -25 119
-10 58 -21 112 -26 121 -7 15 -88 26 -100 14z"/>
					<path d="M5517 3973 c-14 -13 -7 -43 12 -53 11 -5 49 -10 85 -10 69 0 89 13
79 51 -5 17 -16 19 -87 19 -46 0 -86 -3 -89 -7z"/>
					<path d="M7078 1323 c0 -5 -2 -28 -2 -53 -1 -35 -4 -44 -16 -41 -56 16 -77 12
-99 -14 -26 -35 -29 -123 -5 -160 18 -28 60 -33 97 -14 16 9 22 9 25 0 2 -6
10 -11 18 -11 12 0 14 27 13 150 0 123 -3 150 -15 150 -8 0 -15 -3 -16 -7z
m-24 -114 c23 -11 24 -16 22 -70 -2 -32 -9 -66 -16 -74 -19 -23 -62 -15 -78
15 -17 34 -9 110 14 127 22 16 26 16 58 2z"/>
					<path d="M4762 1312 c-9 -6 -12 -43 -10 -148 3 -116 6 -139 18 -139 12 0 16
19 18 103 1 56 7 102 12 102 5 0 23 -27 40 -61 40 -79 62 -78 105 3 17 32 33
58 37 58 4 0 9 -46 10 -102 2 -84 6 -103 18 -103 12 0 15 23 18 139 1 77 -2
144 -7 149 -20 20 -48 -6 -84 -79 -21 -42 -41 -73 -45 -69 -4 3 -25 39 -47 78
-40 71 -57 85 -83 69z"/>
					<path d="M7407 1283 c-4 -3 -7 -14 -7 -24 0 -9 -8 -20 -17 -24 -17 -6 -17 -7
0 -19 13 -10 17 -28 17 -80 0 -79 11 -106 45 -106 31 0 42 18 13 22 -21 3 -23
9 -26 80 -3 73 -2 77 20 80 28 4 27 22 -2 26 -14 2 -20 11 -20 28 0 23 -10 31
-23 17z"/>
					<path d="M5143 1238 c-44 -12 -63 -42 -63 -105 0 -39 5 -58 23 -78 26 -30 79
-42 120 -26 34 13 39 46 5 34 -54 -18 -108 0 -108 38 0 15 10 19 68 21 l67 3
-1 38 c-2 63 -45 92 -111 75z m65 -40 c7 -7 12 -20 12 -30 0 -15 -9 -18 -50
-18 -52 0 -58 7 -40 41 12 22 59 26 78 7z"/>
					<path d="M5352 1238 c-49 -13 -68 -64 -37 -98 13 -14 14 -22 4 -43 -9 -20 -9
-30 -1 -40 9 -11 7 -18 -8 -32 -50 -45 -3 -90 87 -83 57 5 83 23 83 57 0 30
-18 43 -71 55 -42 10 -54 17 -54 32 0 14 10 20 39 25 48 8 80 42 71 76 -4 16
-1 23 10 23 8 0 15 6 15 14 0 16 -93 26 -138 14z m73 -48 c9 -28 -13 -52 -43
-48 -29 4 -48 28 -39 52 10 25 74 22 82 -4z m8 -179 c21 -21 -1 -41 -47 -41
-49 0 -61 9 -51 41 6 18 13 21 47 16 22 -3 45 -10 51 -16z"/>
					<path d="M5585 1244 c-5 -1 -22 -5 -37 -8 -16 -4 -28 -13 -28 -21 0 -12 9 -13
45 -8 29 4 50 1 60 -7 25 -21 18 -40 -15 -40 -50 0 -98 -25 -106 -55 -15 -62
44 -102 106 -72 16 9 30 11 30 6 0 -5 9 -9 21 -9 20 0 21 4 17 90 -1 49 -8 94
-14 100 -12 12 -64 28 -79 24z m55 -138 c0 -32 -22 -48 -62 -44 -63 6 -38 68
28 68 29 0 34 -3 34 -24z"/>
					<path d="M5874 1226 c-28 -21 -15 -26 33 -13 48 12 76 -2 71 -37 -2 -16 -11
-21 -43 -24 -57 -4 -85 -25 -85 -65 0 -18 5 -38 12 -45 16 -16 73 -15 99 1 17
10 23 10 26 0 3 -7 11 -13 19 -13 12 0 13 16 8 88 -5 70 -10 90 -26 105 -24
21 -87 22 -114 3z m106 -121 c0 -24 -6 -37 -21 -45 -44 -24 -96 10 -73 48 9
14 25 20 87 31 4 0 7 -15 7 -34z"/>
					<path d="M6080 1135 c0 -93 2 -105 18 -105 15 0 17 9 16 67 -2 86 8 113 41
113 16 0 25 6 25 15 0 19 -15 19 -45 -1 -23 -15 -25 -15 -25 0 0 9 -7 16 -15
16 -12 0 -15 -18 -15 -105z"/>
					<path d="M6250 1227 c-30 -15 -51 -70 -44 -117 8 -49 45 -80 95 -80 45 0 83
16 74 31 -4 6 -13 5 -25 -1 -49 -26 -114 0 -108 44 3 19 10 21 71 24 l67 3 0
30 c0 62 -69 97 -130 66z m77 -15 c7 -4 16 -23 19 -41 6 -31 6 -32 -22 -27
-16 2 -41 5 -56 5 -32 1 -35 16 -12 49 15 22 48 29 71 14z"/>
					<path d="M6410 1235 c0 -3 12 -51 27 -106 23 -88 29 -99 49 -99 20 0 25 9 39
75 10 41 20 77 24 80 4 2 13 -26 21 -63 17 -90 19 -94 44 -90 17 2 24 18 42
83 11 44 24 90 28 103 5 16 3 22 -8 22 -15 0 -20 -14 -46 -127 -7 -29 -15 -53
-19 -53 -4 0 -13 33 -20 73 -15 82 -26 107 -45 107 -9 0 -20 -30 -32 -90 -10
-49 -22 -90 -25 -90 -4 0 -13 26 -19 58 -21 102 -26 117 -43 120 -9 2 -17 1
-17 -3z"/>
					<path d="M6744 1226 c-28 -21 -15 -26 33 -13 32 8 45 8 57 -2 37 -31 11 -60
-54 -61 -39 -1 -60 -23 -60 -62 0 -43 15 -58 60 -58 20 0 41 5 48 12 9 9 15 9
24 0 27 -27 33 -12 30 75 -3 74 -7 91 -24 106 -24 21 -87 22 -114 3z m106
-123 c0 -31 -25 -52 -62 -53 -21 0 -29 6 -34 25 -8 33 9 53 49 57 42 5 47 2
47 -29z"/>
					<path d="M7198 1234 c-10 -3 -18 -12 -18 -19 0 -12 6 -12 27 -4 37 14 80 2 87
-24 3 -11 4 -22 3 -23 -1 0 -29 -7 -62 -15 -50 -12 -61 -18 -69 -41 -15 -42 8
-78 51 -78 18 0 43 6 54 13 17 10 23 10 26 0 3 -7 11 -13 19 -13 20 0 19 157
-1 184 -14 19 -82 30 -117 20z m82 -166 c-29 -24 -67 -23 -80 2 -17 32 3 58
48 62 31 3 38 -1 46 -21 7 -20 4 -28 -14 -43z"/>
					<path d="M7548 1233 c-10 -2 -18 -11 -18 -18 0 -11 8 -11 37 -3 25 7 43 6 60
-1 27 -13 32 -50 6 -52 -10 -1 -22 -2 -28 -4 -5 -1 -19 -3 -31 -3 -29 -3 -64
-39 -64 -67 0 -48 61 -72 111 -42 17 10 23 10 26 0 3 -7 11 -13 19 -13 13 0
14 15 11 87 -4 75 -7 89 -27 105 -21 17 -63 22 -102 11z m100 -126 c6 -49 -76
-77 -102 -34 -16 25 4 51 45 58 47 9 54 6 57 -24z"/>
					<path d="M7857 1233 c-46 -12 -64 -129 -26 -177 16 -20 29 -26 59 -26 42 0 70
8 70 20 0 5 -18 6 -40 3 -56 -8 -80 15 -80 76 0 27 7 56 16 70 14 20 22 22 60
16 50 -7 61 9 13 19 -34 6 -44 6 -72 -1z"/>
					<path d="M8042 1228 c-46 -23 -54 -136 -11 -179 26 -26 99 -26 123 0 23 26 31
90 17 134 -17 50 -76 70 -129 45z m94 -33 c19 -26 18 -110 -2 -129 -23 -24
-70 -20 -88 6 -21 30 -20 99 1 125 21 26 70 25 89 -2z"/>
					<path d="M8238 1233 c-2 -5 -2 -52 0 -105 3 -77 6 -98 18 -98 11 0 14 18 14
85 0 73 3 86 19 95 13 7 28 7 45 0 25 -9 26 -12 26 -95 0 -68 3 -85 15 -85 11
0 15 18 17 81 3 69 6 84 23 95 16 11 25 11 45 1 23 -12 25 -18 28 -95 2 -64 6
-82 17 -82 12 0 15 17 15 83 0 119 -27 150 -101 112 -27 -14 -31 -14 -45 0
-19 18 -44 19 -80 3 -22 -10 -28 -10 -32 0 -4 13 -19 16 -24 5z"/>
					<path d="M5725 1140 c-4 -6 12 -10 39 -10 25 0 46 4 46 8 0 12 -78 14 -85 2z" />
					<path d="M7730 1050 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
					<path d="M11834 571 c2 -3 -10 -6 -27 -7 -41 -1 -118 -34 -97 -42 7 -3 16 0
18 7 2 6 8 11 14 11 6 0 5 -6 -3 -16 -8 -10 -21 -13 -36 -9 -26 6 -86 -23 -77
-37 3 -4 -2 -8 -10 -8 -9 0 -16 -5 -16 -11 0 -5 -4 -8 -9 -5 -14 9 -98 -96
-132 -165 -18 -35 -32 -80 -33 -100 0 -21 -4 -35 -8 -33 -5 3 -8 -5 -8 -18 0
-13 -3 -41 -5 -63 -4 -31 -3 -35 5 -20 7 15 8 11 4 -17 l-6 -38 300 1 c272 1
301 2 305 17 4 15 5 15 6 0 1 -21 17 -24 24 -5 4 9 6 9 6 0 1 -10 44 -13 181
-13 164 0 180 2 181 18 0 14 2 14 9 -3 8 -18 9 -17 9 7 1 14 4 34 8 44 5 12 3
15 -6 9 -10 -6 -12 0 -6 24 9 41 -3 99 -17 80 -6 -10 -7 -7 -3 9 5 17 1 29
-14 43 -12 11 -21 25 -21 31 0 7 7 3 15 -8 17 -22 20 -12 4 17 -5 11 -15 16
-21 13 -6 -4 -8 -3 -4 4 9 14 -14 63 -27 55 -6 -3 -7 -1 -3 5 8 14 -64 93 -78
85 -6 -3 -8 -3 -4 2 14 15 -74 81 -93 69 -7 -4 -9 -3 -6 3 11 17 -79 55 -116
49 -24 -5 -27 -4 -12 3 l20 10 -20 2 c-54 5 -194 6 -191 0z m282 -47 c18 -14
18 -14 -6 -3 -31 14 -36 19 -24 19 6 0 19 -7 30 -16z m-449 -38 c0 -8 -6 -12
-15 -9 -11 5 -11 7 0 14 7 5 13 9 14 9 0 0 1 -6 1 -14z m44 -4 c-7 -2 -18 1
-23 6 -8 8 -4 9 13 5 13 -4 18 -8 10 -11z m496 -5 c3 -4 -9 -5 -27 -2 -18 2
-35 6 -38 9 -3 3 9 4 27 2 17 -2 34 -6 38 -9z m83 -71 c0 -3 -4 -8 -10 -11 -5
-3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-495 -26 c3 -5 1 -10 -4
-10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-48 -16 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m366 -1 c-7 -2 -19 -2 -25 0 -7 3 -2
5 12 5 14 0 19 -2 13 -5z m117 -3 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6
-1 10 4 10 6 0 11 -4 11 -10z m-717 -15 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15
9 16 15 13 8 -4z m315 -10 c11 -10 11 -15 -3 -29 -9 -9 -27 -16 -41 -16 -13 0
-24 -6 -25 -12 0 -10 -2 -10 -6 0 -7 19 -41 14 -53 -7 -5 -11 -10 -42 -10 -69
0 -42 -3 -50 -17 -47 -10 2 -21 4 -24 4 -4 1 -9 9 -13 19 -3 9 -9 24 -12 32
-4 11 -3 12 4 2 5 -8 9 4 10 37 0 34 8 60 22 79 20 27 24 28 88 24 37 -2 73
-10 80 -17z m372 -73 c0 -24 3 -31 13 -26 8 5 8 3 -2 -9 -7 -9 -11 -30 -9 -45
4 -25 1 -28 -18 -26 -13 1 -27 5 -33 8 -5 3 -11 0 -14 -6 -2 -7 -2 13 0 45 6
76 -3 86 -75 84 l-57 -2 3 32 4 32 67 3 c62 3 68 1 94 -27 18 -20 27 -40 27
-63z m-550 84 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10
-2 10 -4z m557 -28 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m138
2 c-3 -5 0 -10 7 -11 10 0 10 -2 1 -6 -7 -2 -13 -12 -14 -21 0 -13 -2 -13 -8
3 -5 11 -4 25 1 33 12 14 22 16 13 2z m-542 -47 c-7 -2 -19 -2 -25 0 -7 3 -2
5 12 5 14 0 19 -2 13 -5z m265 0 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0
27 -2 18 -5z m57 -13 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0
8 -4 11 -10z m222 -14 c-13 -14 -16 -14 -21 0 -3 8 -2 12 3 9 5 -3 13 0 16 6
4 5 9 8 12 5 3 -3 -1 -12 -10 -20z m-740 -18 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m440 15 c-9 -2 -14 -9 -11 -15 4 -7 2 -8 -5 -4 -15 10
-4 26 17 25 15 -1 15 -2 -1 -6z m-257 -20 c0 -3 -7 -3 -15 1 -8 3 -15 0 -15
-6 0 -6 -3 -9 -6 -5 -11 10 6 26 21 20 8 -3 15 -7 15 -10z m296 5 c4 -7 3 -8
-4 -4 -7 4 -12 2 -12 -5 0 -7 -3 -10 -6 -6 -6 5 3 27 11 27 2 0 7 -5 11 -12z
m-456 -72 c0 -6 -7 -1 -15 10 -8 10 -15 23 -15 28 0 6 7 1 15 -10 8 -10 15
-23 15 -28z m107 22 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m334 12 c21 -12 25 -35 7 -46 -6 -4 -9 -3 -5 2 3 5 -25 8 -66 7 -243 -9 -237
-9 -243 9 -3 10 -2 22 1 28 8 13 280 13 306 0z m169 -10 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-120 -15 c0 -8 -4 -15
-10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m-287 -62 c-7 -2 -19
-2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m214 1 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m26 -28 c11 -29 -17 -36 -140 -36 -92 0 -123 -3
-123 -12 0 -10 -3 -9 -9 1 -5 7 -13 11 -19 7 -12 -7 -26 29 -17 44 4 6 66 10
155 10 116 0 149 -3 153 -14z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
